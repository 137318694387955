;(function(apiKey){
  (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
    v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
      o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
    y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
    z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');

  if(!EG.user) return;

  const isProd = EG.pendoData.stage === "prod" || EG.pendoData.stage == "gamma";
  pendo.initialize({
    visitor: {
      id: `${isProd ? "": "T"}${EG.user.userId}`,
      locale: EG.currentLocale,
      isMobileWebView: uitk.adapt.webView,
      lastLoginDate: EG.pendoData.userLastLoginTime,
      profileCreationTime: EG.pendoData.profileCreationTime,
      ...EG.pendoData.abTestSelectionValues,
      roles: EG.pendoData.roles
    },
    account: {
      id: `${isProd ? "": "T"}${EG.user.companyId}`,
      pos: `${isProd ? "": "T"}${EG.user.productId}`
    }
  });
}(EG.pendoData.pendoKey));